













import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { LOADING_MODE } from '~/constants/images';

export default defineComponent({
  name: 'ImgView',
  props: {
    width: {
      type: [Number, String],
      default: 0
    },
    height: {
      type: [Number, String],
      default: 0
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    defaultSrc: {
      type: String,
      default: ''
    },
    imgClasses: {
      type: String,
      default: ''
    },
    loading: {
      type: String,
      default: LOADING_MODE.LAZY
    }
  },
  setup(props) {
    const defaultImgClasses = 'w-full h-full overflow-hidden object-contain';
    const imgViewClasses = props.imgClasses ? props.imgClasses : defaultImgClasses;
    const imageSource = ref(props.src);
    const checkImageExists = async (url) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.status === 200;
      } catch (error) {
        return false;
      }
    };
    onMounted(async () => {
      const imageExists = await checkImageExists(props.src);
      // Check if the initial image is available
      if (props.defaultSrc && !imageExists) {
        imageSource.value = props.defaultSrc;
      }
    });
    return {
      imageSource,
      imgViewClasses
    };
  }
});

